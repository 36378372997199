import React from 'react'
import { useTranslation } from "react-i18next";
import { useUserManagementFilterContext } from "./UserManagementFilterContext";
import Grid from "@mui/material/Grid";
import Autocomplete from '@mui/material/Autocomplete';
import TextField from "@mui/material/TextField";
import { FilterType } from "./useFilters";


const FieldLayout = ({ children }: { children: React.ReactNode }) => {
  return (
    <Grid item xs={12} md={3}>
      {children}
    </Grid>
  );
};


export const DynamicSearchableMultipleChoiceField = ({ filter }: { filter: FilterType }) => {
  if (!filter.dynamicChoices)
    throw new Error(
      "You must provide dynamicChoices to evaluate the choice-fields for a DynamicMultipleChoiceField"
    );
  const { prefilter } = useUserManagementFilterContext();
  const prefilteredUsers = prefilter(filter);
  const choices = filter.dynamicChoices(prefilteredUsers);
  return <SearchableMultipleChoiceField filter={filter} choices={choices} />;
};


export const SearchableMultipleChoiceField = ({ filter, choices }: { filter: FilterType, choices: string[] }) => {
  const { t } = useTranslation();
  const selectorId = `searchable-select-multi-${filter.label}`;
  const { getFilterValue, setFilterValue } = useUserManagementFilterContext();

  return (
    <>
      <FieldLayout>

        <Autocomplete
          id={selectorId}
          multiple
          disableCloseOnSelect
          filterSelectedOptions
          options={choices.sort((a, b) => (a?.localeCompare(b) || 0))}
          // display translation if there is one - used for the status autocomplete
          // see status translations in utils.ts
          getOptionLabel={(option: string) => t(option)}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="filled"
              label={filter.label}
            />
          )}
          value={getFilterValue(filter.name) || []}
          onChange={(_, values) => setFilterValue(filter.name, values)}
        />
      </FieldLayout>

    </>
  );
};