import { useEffect, useState } from "react";
import { Printshop } from "./types";


export const usePrintshops = (): {
  printshops: Printshop[] | undefined;
  loading: boolean;
} => {
  const [printshops, setPrintshops] = useState<Printshop[] | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchPrintshops = async () => {
      setLoading(true);
      const response = await fetch(`/custom/printshops`);

      if (response?.ok) {
        const data: Printshop[] = await response.json();
        // const data = await response.json();

        setPrintshops(data);
        setLoading(false);
      } else {
        throw new Error(
          `Error while fetching printshops: ${response?.status} - ${response?.statusText}`
        );
      }
    };
    fetchPrintshops();
  }, []);

  return { printshops, loading };
};
