import React from 'react'
import { Autocomplete, Box, Button, Grid } from '@mui/material'
import { useTranslation } from "react-i18next";
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import SendIcon from '@mui/icons-material/Send';
import CircularProgress from '@mui/material/CircularProgress';

import {
  Formik,
  FormikHelpers as FormikActions,
  useFormik,
} from "formik";
import * as Yup from "yup";
import { usePrintshops } from '../UsePrintshops';
import { getCookie } from '../../../../Utils/commons';
import { useSnackbar } from "notistack";
import { InvitedUser } from '../types';

type formFieldsType = {
  first_name: string;
  last_name: string;
  email: string;
  printshop: string;
}

type InvitePrintshopUserProps = {
  pushToUsersList: (invitedUser: InvitedUser) => void,
  isEmailUsed: (email: string) => boolean
}

const InvitePrintshopUser = ({ pushToUsersList, isEmailUsed }: InvitePrintshopUserProps) => {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);

  const { printshops, loading: fetchingPrintshops } = usePrintshops()
  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = async (
    values: formFieldsType,
    actions: FormikActions<formFieldsType>
  ) => {
    const { setSubmitting, resetForm } = actions;
    setSubmitting(true)
    const csrftoken = getCookie("csrftoken");

    // set loading btn state
    const response = await fetch(`/custom/invite/`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        "X-CSRFToken": csrftoken ? csrftoken : "",

      },
      body: JSON.stringify(values)
    });

    if (response.ok) {
      const { username, email, status } = await response.json() as { username: string, email: string, status: "INVITED" }

      enqueueSnackbar(t("An invitation was sent successfully."), {
        variant: "success",
      });

      const printshopName = printshops?.find(printshop => printshop.identifier === values.printshop)?.name as string
      const invitedUser: InvitedUser = {
        username: username ?? "",
        email,
        status,
        printshop: printshopName,
        printshop_id: values.printshop,
        last_login: null,
      }
      pushToUsersList(invitedUser)
      resetForm()
      handleClose()
      // refetch()

    } else {
      enqueueSnackbar(t("An error occurred, please try again."), {
        variant: "error",
      });
    }
    // set loading btn state to false

  };

  const initialValues = {
    first_name: "",
    last_name: "",
    email: "",
    printshop: ""
  };
  const validationSchema = Yup.object().shape({
    first_name: Yup.string().required(t("Required")),
    last_name: Yup.string().required(t("Required")),
    email: Yup.string()
      .email(t("Invalid email"))
      .required(t("Required"))
      .test(
        "unique-email",
        t("This email is already in use"),
        (value) => {
          if (!value) return true; // Skip the uniqueness check if no value (handled by `.required`)
          return !isEmailUsed(value);
        }
      ),
       printshop: Yup.string().length(2).required(t("Required")),
  });

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: handleSubmit,
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    formik.resetForm()
    setOpen(false);
  };

  return (
    <>
      <Grid container justifyContent="flex-end" mb={2}>
        <Grid item xs={12} md={4} >

          <Button
            fullWidth
            startIcon={<PersonAddIcon />}
            size="large"
            variant='outlined'
            onClick={handleClickOpen}
          >
            {t("Invite printshop user")}
          </Button>
        </Grid>

      </Grid>
      <Dialog
        open={open}
        onClose={handleClose}
      >
        <DialogTitle>{t("Invite printshop user")}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t("Please enter the following information")}
          </DialogContentText>
          <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
          >
            {({ isSubmitting, }) => {
              return (
                <form onSubmit={formik.handleSubmit}>
                  <TextField
                    autoFocus
                    required
                    margin="dense"
                    id="first_name"
                    name="first_name"
                    value={formik.values.first_name}
                    onChange={formik.handleChange}
                    label={t("First name")}
                    onBlur={formik.handleBlur}
                    type="text"
                    fullWidth
                    variant="filled"
                    error={
                      formik.touched.first_name &&
                      Boolean(formik.errors.first_name)
                    }
                    helperText={
                      formik.touched.first_name &&
                      formik.errors.first_name
                    }
                  />
                  <TextField
                    required
                    margin="dense"
                    id="last_name"
                    name="last_name"
                    value={formik.values.last_name}
                    onChange={formik.handleChange}
                    label={t("Last name")}
                    onBlur={formik.handleBlur}
                    type="text"
                    fullWidth
                    variant="filled"
                    error={
                      formik.touched.last_name &&
                      Boolean(formik.errors.last_name)
                    }
                    helperText={
                      formik.touched.last_name &&
                      formik.errors.last_name
                    }
                  />
                  <TextField
                    required
                    margin="dense"
                    id="email"
                    name="email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    label={t("Email address")}
                    onBlur={formik.handleBlur}
                    type="email"
                    fullWidth
                    variant="filled"
                    error={
                      formik.touched.email &&
                      Boolean(formik.errors.email)
                    }
                    helperText={
                      formik.touched.email &&
                      formik.errors.email
                    }
                  />
                  <Box my={1}>

                    <Autocomplete
                      id="printshop"
                      options={printshops ? printshops.slice().sort((a, b) => a.identifier.localeCompare(b.identifier)) : []}
                      getOptionLabel={({ identifier, name }) => `${identifier}  ${name && ": " + name}` || ""}
                      noOptionsText={fetchingPrintshops ? t("Loading") : t("No Data")}
                      value={
                        printshops?.find(
                          (printshop) => printshop.identifier === formik.values.printshop
                        ) || null
                      }
                      onChange={(e, value) => formik.setFieldValue("printshop", value?.identifier || "")}
                      onBlur={formik.handleBlur}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="filled"
                          label={t("Printshop")}
                          name="printshop"
                          error={
                            formik.touched.printshop &&
                            Boolean(formik.errors.printshop)
                          }
                          helperText={
                            formik.touched.printshop &&
                            formik.errors.printshop
                          }
                        />
                      )}

                    />
                  </Box>
                </form>
              );
            }}
          </Formik>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant='outlined'>{t("Cancel")}</Button>
          <Button
            disabled={formik.isSubmitting}
            onClick={() => formik.handleSubmit()}
            variant='contained'
            endIcon={formik.isSubmitting ? <CircularProgress size={20} /> : <SendIcon />}
          >
            {t("Invite")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default InvitePrintshopUser