import React, { useMemo } from 'react'
import { createContext, useContext, useEffect, useState } from "react";
import { PrintshopUser } from "../types";
import { FilterName, FilterType, useFilters } from "./useFilters";
import FilterArea from './FilterArea';
import { useUsers } from '../useUsers';
import InvitePrintshopUser from './InvitePrintshopUser';
import { groupBy } from 'lodash';

export type FilterContextType = {
  loading: boolean,
  users: PrintshopUser[],
  unfilteredUsers: PrintshopUser[],
  // refetch: () => void,
  updateUsersList: (user: PrintshopUser, newStatus: "DEACTIVATED" | "ACTIVE" | "VOID") => void,
  setFilterValue: (key: FilterName, value: string[]) => void,
  getFilterValue: (filterName: string) => string[],
  resetFilters: () => void,
  filterValues: FilterValue[],
  prefilter: (filter: FilterType) => PrintshopUser[]
}
export const UserManagementFilterContext = createContext<FilterContextType | null>(null);

export const useUserManagementFilterContext = () => {
  const context = useContext(UserManagementFilterContext);
  if (!context) {
    throw new Error('useUserManagementFilterContext must be used inside the UserManagementFilterContextProvider');
  }
  return context;
};

const filterUsers = (
  users: PrintshopUser[],
  filters: FilterType[],
  filterValues: FilterValue[],
  excludedFilters: FilterType[] = []
) => {
  const filteredUsers = filterValues.reduce(
    (currentUsers, { key, value }) => {
      const filterToApply = filters.find((filter) => filter.name === key);
      if (filterToApply && excludedFilters.includes(filterToApply)) {
        return currentUsers;
      } else {
        return currentUsers.filter((user) =>
          filterToApply?.filterUser(user, value)
        );
      }
    },
    users
  );
  return filteredUsers;
};

type FilterValue = {
  key: FilterName,
  value: string[]
}

export const UserManagementFilterContextProvider = ({ children }: { children: JSX.Element }) => {

  const filters = useFilters();

  const { users: unsortedUsers, loading, pushToUsersList, updateUsersList, isEmailUsed } = useUsers()

  const users = useMemo(() => {
    const usersSortedByEmail = unsortedUsers ? unsortedUsers.sort((a, b) => {
      return a.email.localeCompare(b.email);
    }) : []
    const groupedUsersByStatus = groupBy(usersSortedByEmail, (user) => user.status)
    const INVITED = groupedUsersByStatus.INVITED ?? []
    const ACTIVE = groupedUsersByStatus.ACTIVE ?? []
    const DEACTIVATED = groupedUsersByStatus.DEACTIVATED ?? []
    const INACTIVE = groupedUsersByStatus.INACTIVE ?? []
    const sortedUsers = [...INVITED, ...ACTIVE, ...DEACTIVATED, ...INACTIVE]
    return sortedUsers
  }, [unsortedUsers])


  const [filteredUsers, setFilteredUsers] = useState<PrintshopUser[]>([]);
  const [filterValues, setFilterValues] = useState<FilterValue[]>([]);

  const setFilterValue = (key: FilterName, value: string[]) => {
    setFilterValues(
      filterValues.filter((pair) => pair.key !== key).concat({ key, value })
    );
  };

  const getFilterValue = (filterName: string) => {
    return filterValues.find(({ key }) => key === filterName)?.value
      ?? filters.find((filter) => filter.name === filterName)?.defaultValue
      ?? [];
  };

  const resetFilters = () => {
    setFilterValues([]);
  };

  // const resetFilter = (filterName: string) => {
  //   setFilterValues(filterValues.filter((pair) => pair.key !== filterName));
  // };

  useEffect(() => {
    if (filterValues && users) {
      setFilteredUsers(
        filterUsers(users, filters, filterValues)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterValues, users]);


  return (
    <>
      <UserManagementFilterContext.Provider
        value={{
          loading,
          users: filteredUsers,
          unfilteredUsers: users,
          // refetch,
          updateUsersList,
          setFilterValue,
          getFilterValue,
          resetFilters,
          filterValues,
          prefilter: (filter: FilterType) => {
            const filteredUsers = filterUsers(
              users,
              filters,
              filterValues,
              [filter]
            );

            return filteredUsers;
          },
        }}
      >
        <InvitePrintshopUser pushToUsersList={pushToUsersList} isEmailUsed={isEmailUsed}  />
        <FilterArea>
          {filters.map((filter, index) => {
            const FilterField = filter.fieldType;
            return <FilterField key={index} filter={filter} />;
          })}
        </FilterArea>
        {children}
      </UserManagementFilterContext.Provider>
    </>
  );

};
