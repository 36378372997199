import React, { useState } from "react";
import { useTranslation } from "react-i18next";
// Mui
import { styled } from "@mui/material/styles";
import { useTheme } from "@mui/material/styles";
import {
  AccordionSummary,
  AccordionDetails,
  Grid,
  Box,
  Typography,
} from "@mui/material";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FilterListIcon from "@mui/icons-material/FilterList";
import { useUserManagementFilterContext } from "./UserManagementFilterContext";
import { getGenericColor } from "../../../../Modules/Statistics/utils";
import ResetFiltersButton from "./ResetFilterButton";



const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion {...props} />
))(() => {
  const theme = useTheme();

  return {
    "& .MuiAccordionSummary-root": {
      borderRadius: "4px 4px 0 0",
      height: "50px",
      minHeight: "50px",
      color: "white",
      backgroundColor: getGenericColor(theme, 0),
    },
    "& .css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root.Mui-expanded": {
      height: "50px",
      minHeight: "50px",
      backgroundColor: getGenericColor(theme, 0),
    },
  };
});

const FilterArea:React.FC<React.ReactNode> = ({children}) => {
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState(true);
  const { resetFilters } = useUserManagementFilterContext();


  return (
    <>
      <Accordion
        elevation={3}
        sx={{ borderRadius: "4px" }}
        expanded={expanded}
        onChange={() => setExpanded((expanded: boolean) => !expanded)}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon htmlColor="white" />}
          aria-controls="filter-content"
          id="filter-header"
        >
          <Grid container alignItems="center" spacing={1}>
            <Grid item sx={{ display: "flex" }}>
              <FilterListIcon />
            </Grid>
            <Grid item>
              <Typography>{t("Filter")}</Typography>
            </Grid>
          </Grid>
        </AccordionSummary>

        <AccordionDetails sx={{ borderRadius: "4px" }}>
          <Grid container spacing={2} alignItems="stretch" mb={2} mt={2}>
            {children}
          </Grid>
          <Grid container justifyContent="flex-end" mb={2}>
            <ResetFiltersButton
              size="large"
              variant="outlined"
              onClick={resetFilters}
            >
              {t("Clear filters")}
            </ResetFiltersButton>
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Box
        sx={{
          marginTop: 3,
        }}
      />
    </>
  );
};

export default FilterArea;
