import React from 'react'
import Grid from '@mui/material/Grid'
import { PrintshopUser } from '../types';
import DeleteUserButton from './DeleteUserButton';
import ResendInvitationButton from './ResendInvitationButton';
import Tooltip from '@mui/material/Tooltip';
import { useTranslation } from 'react-i18next';
import ActivateUserButton from './ActivateUserButton';

type TableActionButtonsProps = {
  user: PrintshopUser,
}
const TableActionButtons = ({ user }: TableActionButtonsProps) => {
  const { t } = useTranslation();

  return (
    <Grid container justifyContent="flex-end" wrap="nowrap">
      <Grid item>
        <Tooltip title={t("Resend invitation")}>
          <span>
            <ResendInvitationButton user={user} />
          </span>
        </Tooltip>
      </Grid>
      <Grid item>
        <Tooltip title={t("Activate")}>
          <span>
            <ActivateUserButton user={user} />
          </span>
        </Tooltip>
      </Grid>
      <Grid item>
        <Tooltip title={user.status === "ACTIVE" ? t("Deactivate") : t("Delete")}>
          <span>
            <DeleteUserButton user={user} />
          </span>
        </Tooltip>
      </Grid>
    </Grid>
  )
}

export default TableActionButtons