import React from "react";
import { useTranslation } from "react-i18next";
import { DynamicSearchableMultipleChoiceField } from "./FilterFields";
import { PrintshopUser } from "../types";


export type FilterName = "email" | "printshop" | "printshop_id" | "status"
export type FilterType = {
  name: FilterName;
  label: string;
  defaultValue: string[];
  dynamicChoices: (unfilteredUsers: PrintshopUser[]) => string[];
  fieldType: React.ElementType;
  filterUser: (user: PrintshopUser, filterValue: string[]) => boolean;
}

export const useFilters = (): FilterType[] => {
  const { t } = useTranslation();

  return [
    {
      name: "email",
      label: t("Email"),
      defaultValue: [],
      dynamicChoices: (unfilteredUsers: PrintshopUser[]) => {
        const emails = unfilteredUsers.map((user) => user.email)

        return emails

      },
      fieldType: DynamicSearchableMultipleChoiceField,
      filterUser: (user: PrintshopUser, filterValue: string[]) => {
        return (
          filterValue.length === 0 ||
          filterValue.includes(user.email)
        );
      },
    },
    {
      name: "printshop",
      label: t("Printshop Name"),
      defaultValue: [],
      dynamicChoices: (unfilteredUsers: PrintshopUser[]) => {
        const uniquePrintshops: string[] =
          unfilteredUsers
            .map((user) => user.printshop)
            .filter((printshop, index, self) => {
              return index === self.indexOf(printshop);
            })

        return uniquePrintshops
      },
      fieldType: DynamicSearchableMultipleChoiceField,
      filterUser: (user: PrintshopUser, filterValue: string[]) => {
        return (
          filterValue.length === 0 ||
          filterValue.includes(user.printshop)
        );
      },
    },
    {
      name: "printshop_id",
      label: t("Printshop ID"),
      defaultValue: [],
      dynamicChoices: (unfilteredUsers: PrintshopUser[]) => {
        const uniquePrintshopIDs: string[] =
          unfilteredUsers
            .map((user) => user.printshop_id)
            .filter((printshop_id, index, self) => {
              return index === self.indexOf(printshop_id);
            })

        return uniquePrintshopIDs
      },
      fieldType: DynamicSearchableMultipleChoiceField,
      filterUser: (user: PrintshopUser, filterValue: string[]) => {
        return (
          filterValue.length === 0 ||
          filterValue.includes(user.printshop_id)
        );
      },
    },
    {
      name: "status",
      label: t("Status"),
      defaultValue: [],
      dynamicChoices: (unfilteredUsers: PrintshopUser[]) => {
        const uniqueStatus: string[] =
          unfilteredUsers
            .map((user) => user.status)
            .filter((status, index, self) => {
              return index === self.indexOf(status);
            })

        return uniqueStatus
      },
      fieldType: DynamicSearchableMultipleChoiceField,
      filterUser: (user: PrintshopUser, filterValue: string[]) => {
        return (
          filterValue.length === 0 ||
          filterValue.includes(user.status)
        );
      },
    },
  ];
};
