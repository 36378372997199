import React, { Fragment } from "react";
import GroupIcon from '@mui/icons-material/Group';
import { useTranslation } from "react-i18next";
import UserManagementPage from "./UserManagementPage";
import { ModuleRoute } from "../../../Components/ModuleRoute";

type Props = {
  sortOrder: number;
};

export const UserManagementModule = (props: Props) => {
  const { sortOrder } = props;
  const { t } = useTranslation();
  return (
    <Fragment>
      <ModuleRoute
        sortOrder={sortOrder}
        name={"usermanagement"}
        displayName={() => t("User Management")}
        path={"/usermanagement"}
        icon={() => <GroupIcon />}
        loginRequired={true}
        hideWhenLoggedIn={false}
        component={() => <UserManagementPage />}
        permissions={["can_manage_printshop_users"]}
       
      />
    </Fragment>
  );
};
