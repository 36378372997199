import React from "react";
import { useTranslation } from "react-i18next";
// Material-UI
import { Typography, Grid } from "@mui/material";
import { useTranslatedLabel } from "./useAdditionalInfo";

export const AdditionalData = (props: {
  additionalInfo: string | undefined;
}) => {
  const { t } = useTranslation();
  const translateLabel = useTranslatedLabel();
  const { additionalInfo } = props;

  const info = additionalInfo && JSON.parse(additionalInfo);

  return (
    <Grid container direction="column" item alignItems="flex-start">
      {info && Object.keys(info).length > 0 && (
        <>
          <Typography gutterBottom />
          <Typography variant="subtitle2" color="textSecondary">
            {t("Additional information")}
          </Typography>
          {Object.keys(info).map((key, index) => {
            return (
              <Grid key={index} container item justifyContent="space-between">
                <Typography variant="subtitle2" color="textSecondary">
                  {translateLabel(key)}
                </Typography>
                <Typography variant="subtitle2" color="textSecondary">
                  {`${info[key]}`}
                </Typography>
              </Grid>
            );
          })}
        </>
      )}
    </Grid>
  );
};
