import React, { useState } from 'react'
import IconButton from '@mui/material/IconButton';
import { PrintshopUser } from '../types';
import { getCookie } from '../../../../Utils/commons';
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import CircularProgress from '@mui/material/CircularProgress';
import { useUserManagementFilterContext } from './UserManagementFilterContext';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';

type ActivateUserButtonProps = {
  user: PrintshopUser
}

const ActivateUserButton = ({ user }: ActivateUserButtonProps) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false)
  const { updateUsersList } = useUserManagementFilterContext();

  const handleClick = async () => {
    setLoading(true)
    const { username, email, status } = user
    const csrftoken = getCookie("csrftoken");
    const response = await fetch(`/custom/usermanagement/activate/`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        "X-CSRFToken": csrftoken ? csrftoken : "",

      },
      body: JSON.stringify({
        username,
        email,
        status
      })
    });

    if (response.ok) {
      const { status } = await response.json() as { status: "ACTIVE" }
      updateUsersList(user, status)
      enqueueSnackbar(t("User was activated successfully."), {
        variant: "success",
      });

    } else {
      enqueueSnackbar(t("An error occurred, please try again."), {
        variant: "error",
      });
    }
    setLoading(false)

  }

  return (
    <IconButton
      aria-label="Activate"
      onClick={handleClick}
      disabled={loading || user.status !== "DEACTIVATED"}
    >
      {loading ?
        <CircularProgress size={20} />
        :
        <PersonAddAlt1Icon />
      }
    </IconButton>
  )
}

export default ActivateUserButton