import React, { useState } from 'react'
import { Button, CircularProgress } from '@mui/material'
import { useTranslation } from "react-i18next";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import { PrintshopUser } from '../types';
import { getCookie } from '../../../../Utils/commons';
import { useSnackbar } from 'notistack';
import { useUserManagementFilterContext } from './UserManagementFilterContext';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';

type DeleteUserProps = {
  user: PrintshopUser

}

const DeleteUserButton = ({ user }: DeleteUserProps) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false)
  const { enqueueSnackbar } = useSnackbar();
  const { updateUsersList } = useUserManagementFilterContext();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = async () => {
    setLoading(true)
    handleClose()
    const { username, email, status } = user
    const csrftoken = getCookie("csrftoken");
    const response = await fetch(`/custom/usermanagement/remove/`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        "X-CSRFToken": csrftoken ? csrftoken : "",

      },
      body: JSON.stringify({
        username,
        email,
        status
      })
    });

    if (response.ok) {
      const { status } = await response.json() as { status: "DEACTIVATED" | "VOID" }
      updateUsersList(user, status)
      enqueueSnackbar(t("User was deleted successfully."), {
        variant: "success",
      });
      if (status !== "VOID") {
        setLoading(false)
      }
    } else {
      enqueueSnackbar(t("An error occurred, please try again."), {
        variant: "error",
      });
      setLoading(false)
    }
  }

  let dialogTitle = "";
  let dialogText = "";
  switch (user.status) {
    case "INVITED":
      dialogTitle = t("Delete invitation");
      dialogText = t("Please confirm that you want to delete the invitation for user '{{email}}'", {
        email: user.email
      });
      break;
    case "ACTIVE":
      dialogTitle = t("Deactivate user");
      dialogText = t("Please confirm that you want to deactivate the user '{{email}}'", {
        email: user.email
      });
      break;
    case "INACTIVE":
      dialogTitle = t("Delete user");
      dialogText = t("Please confirm that you want to delete the user '{{email}}'", {
        email: user.email
      });
      break;
    default:
      break;
  }

  return (
    <>
      <IconButton
        aria-label="delete user"
        onClick={handleClickOpen}
        disabled={loading || user.status === "DEACTIVATED"}
      >
        {loading ?
          <CircularProgress size={20} />
          :
          <PersonRemoveIcon />
        }
      </IconButton>

      <Dialog
        open={open}
        onClose={handleClose}
      >
        <DialogTitle>{dialogTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {dialogText}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            variant='outlined'
          >
            {t("Cancel")}
          </Button>
          <Button
            onClick={handleSubmit}
            variant='contained'
          >
            {t("Confirm")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default DeleteUserButton