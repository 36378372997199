import React from "react";
import { Grid } from "@mui/material";
import { MarkingProductFragment } from "../../gql/graphql";
import AddInformationCollapse from "../AdditionalInfo/AddInformationCollapse";

type Props = {
  markingProduct: MarkingProductFragment;
};

export const AdditionalInfoInput = ({ markingProduct }: Props) => {
  return (
    <Grid item xs={12}>
      <AddInformationCollapse markingProduct={markingProduct} />
    </Grid>
  );
};
