import React from 'react'
import { useTranslation } from "react-i18next";
import PageContainer from '../../../Components/PageContainer/PageContainer';
import { getGenericColor } from '../../../Modules/Statistics/utils';
import { useTheme } from "@mui/material/styles";
import ContentWrapper from '../../../Modules/Statistics/components/ContentWrapper';
import UsersTable from './components/UsersTable';
import { UserManagementFilterContextProvider } from './components/UserManagementFilterContext';

const UserManagementPage = () => {

  const { t } = useTranslation();
  const theme = useTheme();

  const header = [{ name: t("Printshop Users"), urlRegex: /^\/usermanagement/ }];

  return (
    <PageContainer
      title={t("User Management")}
      subTitle={""}
      header={{ breadcrumbs: header }}
    >
      <>
        <UserManagementFilterContextProvider>
        <ContentWrapper
          label={t("Printshop Users")}
          bgcolor={getGenericColor(theme, 1)}
          body={<UsersTable />}
          />
          </UserManagementFilterContextProvider>
      </>
    </PageContainer>)
}

export default UserManagementPage