import React from 'react'
import { StatusType } from '../types'
import Chip, { ChipProps } from '@mui/material/Chip';
import { alpha, styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { createStatusMap } from '../utils';


interface StyledChipProps extends ChipProps {
  status: StatusType;
}

const StyledChip = styled(Chip)<StyledChipProps>(({ theme, status }) => {
  const chipColorLookup = {
    INVITED: theme.palette.warning.main,
    ACTIVE: theme.palette.success.main,
    INACTIVE: theme.palette.secondary.main,
    DEACTIVATED: theme.palette.error.main,
  }

  return ({
    width: 111,
    lineHeight: 1,
    color: chipColorLookup[status],
    background: alpha(chipColorLookup[status], 0.16),
    border: `1px solid ${chipColorLookup[status]}`
  })
});


const StatusChip = ({ status }: { status: StatusType }) => {
  const { t } = useTranslation();
  const statusMap = createStatusMap(t);

  return (
    <StyledChip label={statusMap.get(status)} status={status} />
  )
}

export default StatusChip