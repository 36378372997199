import React from 'react'
import { PrintshopUser } from '../types'
import TableActionButtons from './TableActionButtons';
import StatusChip from './StatusChip';
import TableCell, { TableCellProps } from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { styled } from "@mui/material/styles";
import moment from 'moment';

const StyledTableCell = styled((props: TableCellProps) => (
  <TableCell {...props} />
))(() => {
  return {
    color: "inherit"
  };
});

const UserRow = ({ user }: { user: PrintshopUser }) => {

  return (
    <TableRow
      hover
      tabIndex={-1}
    >
      <StyledTableCell align="left" color='secondary'>
        {user.email}
      </StyledTableCell>
      <StyledTableCell align="left">
        {user.printshop}
      </StyledTableCell>
      <StyledTableCell align="left">
        {user.printshop_id}
      </StyledTableCell>
      <StyledTableCell align="center">
        <StatusChip status={user.status} />
      </StyledTableCell>
      <StyledTableCell align="center">
        {user.last_login ? moment(user.last_login).format("YYYY-MM-DD") : "----------------"}
      </StyledTableCell>
      <StyledTableCell align="right">
        <TableActionButtons user={user} />
      </StyledTableCell>
    </TableRow>)
}

export default UserRow