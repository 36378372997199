import React, { useState } from 'react'
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import LoadingButton from "@mui/lab/LoadingButton";
import DownloadIcon from "@mui/icons-material/Download";

const DownloadExcelButton = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false)

  const handleClick = async () => {
    setLoading(true)
    const response = await fetch(`/custom/usermanagement/excel/`);
    if (response.ok) {
      let blob = await response.blob();
      const file = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = file;
      link.download = `TE-user-management-data-download.xlsx`;
      link.click();
      enqueueSnackbar(t("The file was downloaded successfully."), {
        variant: "success",
      });

    } else {
      enqueueSnackbar(t("An error occurred, please try again."), {
        variant: "error",
      });
    }
    setLoading(false)

  }

  return (
    <LoadingButton
      loading={loading}
      disabled={loading}
      onClick={handleClick}
      loadingPosition="start"
      startIcon={<DownloadIcon />}
      variant="text"
    >
      {t("Download full list as Excel")}
    </LoadingButton>
  )
}

export default DownloadExcelButton