import { useEffect, useState } from "react";
import { InvitedUser, PrintshopUser } from "./types";



export const useUsers = (): {
  users: PrintshopUser[];
  loading: boolean;
  pushToUsersList: (invitedUser: InvitedUser) => void;
  updateUsersList: (user: PrintshopUser, newStatus: "DEACTIVATED" | "ACTIVE" | "VOID") => void;
  isEmailUsed: (email: string) => boolean;
} => {
  const [users, setUsers] = useState<PrintshopUser[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const fetchUsers = async (initialFetch?: boolean) => {
    if (initialFetch) {
      setLoading(true);
    }
    const response = await fetch(`/custom/usermanagement`);
    if (response?.ok) {
      const data: PrintshopUser[] = await response.json();
      setUsers(data);
    } else {
      throw new Error(
        `Error while fetching printshop users: ${response?.status} - ${response?.statusText}`
      );
    }
    if (initialFetch) {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUsers(true);
  }, []);

  const pushToUsersList = (invitedUser: InvitedUser) => {
    setUsers(users => [invitedUser, ...users])
  }

  const updateUsersList = (user: PrintshopUser, newStatus: "DEACTIVATED" | "ACTIVE" | "VOID") => {
    let usersCopy = [...users]
    if (newStatus === "VOID") {
      usersCopy = usersCopy.filter(({ email, username }) => email !== user.email || username !== user.username)
    } else {
      const index = users.findIndex(({ email, username }) => email === user.email && username === user.username)
      if (index > -1) {
        usersCopy[index] = { ...user, status: newStatus }
      }
    }
    setUsers(usersCopy)
  }

  const isEmailUsed = (email: string) => {
    return users.findIndex(user => user.email === email) > -1
  }

  return { users, loading, pushToUsersList, updateUsersList, isEmailUsed };
};
