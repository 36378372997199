import React, { useState } from 'react'
import IconButton from '@mui/material/IconButton';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import { PrintshopUser } from '../types';
import { getCookie } from '../../../../Utils/commons';
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import CircularProgress from '@mui/material/CircularProgress';

type ResendInvitationButtonProps = {
  user: PrintshopUser
}

const ResendInvitationButton = ({ user }: ResendInvitationButtonProps) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [sending, setSending] = useState(false)

  const handleClick = async () => {
    setSending(true)
    const { username, email, status } = user
    const csrftoken = getCookie("csrftoken");
    const response = await fetch(`/custom/usermanagement/resend_invitation/`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        "X-CSRFToken": csrftoken ? csrftoken : "",

      },
      body: JSON.stringify({
        username,
        email,
        status
      })
    });

    if (response.ok) {
      enqueueSnackbar(t("An invitation was sent successfully."), {
        variant: "success",
      });

    } else {
      enqueueSnackbar(t("An error occurred, please try again."), {
        variant: "error",
      });
    }
    setSending(false)

  }

  return (
    <IconButton
      aria-label="re-send invitation"
      onClick={handleClick}
      disabled={sending || user.status !== 'INVITED'}
    >
      {sending ?
        <CircularProgress size={20} />
        :
        <ForwardToInboxIcon />
      }
    </IconButton>
  )
}

export default ResendInvitationButton