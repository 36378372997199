import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useTranslation } from "react-i18next";
import { styled } from "@mui/material/styles";
import { useTheme } from "@mui/material/styles";
import { Box, BoxProps, Typography } from '@mui/material';
import IconButton from "@mui/material/IconButton";
import FirstPage from "@mui/icons-material/FirstPage";
import LastPage from "@mui/icons-material/LastPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import { useUserManagementFilterContext } from './UserManagementFilterContext';
import Empty from '../../../../Components/Empty';
import UserRow from './UserRow';
import DownloadExcelButton from './DownloadExcelButton';

const TableFooter = styled((props: BoxProps) => <Box {...props} />)(() => {
  const theme = useTheme();

  return {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column-reverse",
      alignItems: "flex-start",
    },
  };
});

const PaginationBox = styled((props: BoxProps) => <Box {...props} />)(
  () => {
    const theme = useTheme();

    return {
      display: "flex",
      alignItems: "center",
      marginLeft: "16px",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        justifyContent: "space-between",
        marginLeft: "8px",
        marginBottom: "16px",
      },
    };
  }
);


interface Column {
  id: string;
  label: string;
  minWidth?: number;
  align?: "left" | "right" | "inherit" | "center" | "justify";
}

export default function UsersTable() {
  const { users, loading } = useUserManagementFilterContext();

  const [page, setPage] = React.useState(0);
  const [rowsPerPage /*, setRowsPerPage */ ] = React.useState(10);
  const { t } = useTranslation();

  const columns: readonly Column[] = [
    { id: 'Email', label: t('Email'), minWidth: 170 },
    { id: 'Printshop', label: t('Printshop'), minWidth: 170 },
    {
      id: 'Printshop ID',
      label: t('Printshop ID'),
      minWidth: 120,
      align: 'left',
    },
    {
      id: 'Status',
      label: t('Status'),
      minWidth: 170,
      align: 'center',
    },
    {
      id: 'Last Login',
      label: t('Last Login'),
      minWidth: 170,
      align: 'center',
    },
    {
      id: 'Actions',
      label: t('Actions'),
      minWidth: 110,
      align: 'right',
    },
  ];
  if (loading) {
    return <p>Loading ...</p>;
  }
  if (!users || (users.length === 0)) {
    return <Empty />;
  }

  const userRows = users
    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    .map((user) => <UserRow user={user} key={user.email + user.username} />);

  return (
    <>
      <TableContainer sx={{ maxHeight: 800 }}>
        <Table stickyHeader aria-label="sticky table" size="small">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {userRows}
          </TableBody>
        </Table>
      </TableContainer>

      <TableFooter mt={2}>
        <DownloadExcelButton />
        <PaginationBox>
          <Typography variant="body2">
            {t("Page {{currentPage}} of {{totalPages}}", {
              currentPage: page + 1,
              totalPages: Math.ceil(users.length / rowsPerPage)
            })}
          </Typography>
          <Box ml={1}>
            <IconButton
              onClick={() => setPage(0)}
              disabled={page === 0}
              aria-label="first page"
            >
              <FirstPage />
            </IconButton>
            <IconButton
              onClick={() => setPage(page - 1)}
              disabled={page === 0}
              aria-label="previous page"
            >
              <KeyboardArrowLeft />
            </IconButton>
            <IconButton
              onClick={() => setPage(page + 1)}
              disabled={page + 1 === Math.ceil(users.length / rowsPerPage)}
              aria-label="next page"
            >
              <KeyboardArrowRight />
            </IconButton>
            <IconButton
              onClick={() => setPage(Math.ceil(users.length / rowsPerPage) - 1)}
              disabled={page + 1 >= Math.ceil(users.length / rowsPerPage)}
              aria-label="last page"
            >
              <LastPage />
            </IconButton>
          </Box>
        </PaginationBox>
      </TableFooter>
    </>
  );
}
